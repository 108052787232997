import confetti from "canvas-confetti";
import { useEffect, useMemo, useState } from "react";

const TIME_OF_RELEASE = new Date("Dec 31 2020 20:00:00 GMT+1");

const YEAR = 2020;
const MS_PER_DAY = 100;
const MAX_BAR_WIDTH = 200;

const ZERO_WIDTH_SPACE = String.fromCharCode(8288);

function Viech({ name, value, rank, max, isWinner }) {
  useEffect(() => {
    if (isWinner) {
      confetti({ particleCount: 200, spread: 120 });
    }
  }, [isWinner]);

  return (
    <li>
      <h4 className="mb-2">
        {rank + 1}. {name}
      </h4>
      <div className="relative">
        <div
          className="relative px-2 py-1 bg-black text-white overflow-hidden z-10"
          style={{
            width: 1 + (value / max) * MAX_BAR_WIDTH,
            transition: `width ${MS_PER_DAY}ms linear`,
          }}
        >
          {value > 0 ? value : ZERO_WIDTH_SPACE}
        </div>
        <span className="absolute top-0 left-0 px-2 py-1">
          {value > 0 ? value : ZERO_WIDTH_SPACE}
        </span>
      </div>
    </li>
  );
}

function ViechScore() {
  const [data, setData] = useState(null);
  const [day, setDay] = useState(0);
  const [categoryIndex, setCategoryIndex] = useState(0);

  const hasReachedEnd = day >= 364;

  const max = useMemo(
    () =>
      data
        ? Math.max(
            ...Object.values(data)
              .map((viecherValues) => Object.values(viecherValues))
              .flat(Infinity)
          )
        : 0,
    [data]
  );

  useEffect(() => {
    fetch(`/${YEAR}.json`)
      .then((r) => r.json())
      .then((data) => setData(data));
  }, []);

  useEffect(() => {
    if (!data || hasReachedEnd) {
      return;
    }
    const interval = setInterval(() => {
      setDay((day) => day + 1);
    }, MS_PER_DAY);
    return () => {
      clearInterval(interval);
    };
  }, [data, hasReachedEnd]);

  if (!data) {
    return null;
  }

  const categories = Object.entries(data);
  const [category, viecherValues] = categories[categoryIndex];

  return (
    <main className="font-body antialiased z-10">
      <div className="container lg:grid grid-cols-12 grid-rows-1 gap-24 max-h-screen">
        <section className="col-span-5 my-12 lg:my-16 pb-16 lg:pb-0 lg:pr-16 border-b lg:border-b-0 lg:border-r border-gray-300 ">
          <div className="mb-16">
            <h1 className="font-display text-4xl lg:text-5xl mb-4">
              Leet/Zeet/420 <br /> Awards
            </h1>
            <h2 className="font-body text-xl lg:text-2xl opacity-50">
              2020 Edition
            </h2>
          </div>
          <div className="">
            <h3 className="lg:text-lg opacity-50 mb-2 lg:mb-4">Categories</h3>
            <ul className="flex lg:block items-center space-x-4 lg:space-x-0 lg:space-y-2">
              <li>
                <a
                  className={`flex lg:text-lg hover:opacity-100 ${
                    categoryIndex === 0 ? "" : "opacity-50"
                  }`}
                  onClick={() => {
                    setCategoryIndex(0);
                    setDay(0);
                  }}
                  href="#"
                >
                  <span
                    className={`${
                      categoryIndex === 0 ? "block mr-2" : "hidden"
                    }`}
                  >
                    &#8594;{" "}
                  </span>
                  420
                </a>
              </li>
              <li>
                <a
                  className={`flex lg:text-lg hover:opacity-100 ${
                    categoryIndex === 1 ? "" : "opacity-50"
                  }`}
                  onClick={() => {
                    setCategoryIndex(1);
                    setDay(0);
                  }}
                  href="#"
                >
                  <span
                    className={`${
                      categoryIndex === 1 ? "block mr-2" : "hidden"
                    }`}
                  >
                    &#8594;{" "}
                  </span>
                  leet
                </a>
              </li>
              <li>
                <a
                  className={`flex lg:text-lg hover:opacity-100 ${
                    categoryIndex === 2 ? "" : "opacity-50"
                  }`}
                  onClick={() => {
                    setCategoryIndex(2);
                    setDay(0);
                  }}
                  href="#"
                >
                  <span
                    className={`${
                      categoryIndex === 2 ? "block mr-2" : "hidden"
                    }`}
                  >
                    &#8594;{" "}
                  </span>
                  zeet
                </a>
              </li>
              <li>
                <a
                  className={`flex lg:text-lg hover:opacity-100 ${
                    categoryIndex === 3 ? "" : "opacity-50"
                  }`}
                  onClick={() => {
                    setCategoryIndex(3);
                    setDay(0);
                  }}
                  href="#"
                >
                  <span
                    className={`${
                      categoryIndex === 3 ? "block mr-2" : "hidden"
                    }`}
                  >
                    &#8594;{" "}
                  </span>
                  fail
                </a>
              </li>
              <li>
                <a
                  className={`flex lg:text-lg hover:opacity-100 ${
                    categoryIndex === 4 ? "" : "opacity-50"
                  }`}
                  onClick={() => {
                    setCategoryIndex(4);
                    setDay(0);
                  }}
                  href="#"
                >
                  <span
                    className={`${
                      categoryIndex === 4 ? "block mr-2" : "hidden"
                    }`}
                  >
                    &#8594;{" "}
                  </span>
                  soli
                </a>
              </li>
            </ul>
          </div>
        </section>
        <section className="col-span-7 my-16 lg:my-0 lg:py-16 overflow-y-scroll">
          <h3 className="lg:text-lg opacity-50 mb-4">
            Category 0{categoryIndex + 1}
          </h3>
          <h4 className="font-display text-4xl lg:text-5xl mb-12">
            {category}
          </h4>
          <p className="text-lg uppercase opacity-50 mb-12 animate-bounce">
            &darr; Day {1 + day} of the year {YEAR} &darr;
          </p>
          <div>
            <ol className="space-y-8">
              {Object.entries(viecherValues)
                .sort((v1, v2) => (v1[1][day] > v2[1][day] ? -1 : 1))
                .map(([name, values], rank) => (
                  <Viech
                    key={name}
                    value={values[day]}
                    isWinner={rank === 0 && hasReachedEnd}
                    {...{ name, rank, max }}
                  />
                ))}
            </ol>
          </div>
        </section>
      </div>
      <div className="fixed inset-0 h-screen w-screen -z-10 opacity-50">
        <div className="gradient-yellow opacity-50" />
        <div className="gradient-red opacity-50" />
      </div>
    </main>
  );
}

export function App() {
  const [secondsToRelease, setSecondsToRelease] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      // can't just subtract 1 each step as interval gets throttled for inactive tabs
      const diff = (TIME_OF_RELEASE - new Date()) / 1000;
      setSecondsToRelease(diff);
      if (diff <= 0) {
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (secondsToRelease == null) {
    return null;
  }

  if (secondsToRelease > 0) {
    return (
      <div className="h-screen flex items-center text-center">
        <div className="container">
          <h1 className="font-display text-4xl lg:text-5xl mb-8">
            Leet/Zeet/420 Awards
          </h1>
          <h2 className="font-body text-xl lg:text-2xl opacity-50">
            — {new Date(1000 * secondsToRelease).toISOString().substr(11, 8)} —
          </h2>
        </div>
        <div className="fixed inset-0 h-screen w-screen -z-10 opacity-50">
          <div className="gradient-yellow opacity-50" />
          <div className="gradient-red opacity-50" />
        </div>
      </div>
    );
  }

  return <ViechScore />;
}
